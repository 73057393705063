:root {
  --font-color: #eee;
  --background-color: #333;
  --link-color: lightblue;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

a {
  color: var(--link-color);
}


.logo {
  /* float: left; */
  width: 80vw;
  height: 91px;
  margin: 16px 24px 16px 0;
  background-image: url(./assets/apple-touch-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  padding-left: 104px;
  padding-top: 16px;
}
