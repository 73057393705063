.fail-list {
  display: flex;
  flex-direction: column;
  justify-content: 'space-between';
  margin-horizontal: auto;
  flex-wrap: wrap;
  align-items: center;
}

.fail-card {
  margin-bottom: 20px;
  justify-content: space-between;
  min-width: 240px;
  max-width: 80vw;
}